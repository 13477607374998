<template>
  <div class="day-stat-row">
    <div class="day">
      <DayStat :loading="computedData.loading" :stat="computedData.days[0]" />
    </div>
    <div class="day">
      <DayStat :loading="computedData.loading" :stat="computedData.days[1]" />
    </div>
    <div class="day">
      <DayStat :loading="computedData.loading" :stat="computedData.days[2]" />
    </div>
    <div class="day">
      <DayStat :loading="computedData.loading" :stat="computedData.days[3]" />
    </div>
    <div class="day">
      <DayStat :loading="computedData.loading" :stat="computedData.days[4]" />
    </div>
    <div class="day">
      <DayStat :loading="computedData.loading" :stat="computedData.days[5]" />
    </div>
    <div class="day">
      <DayStat :loading="computedData.loading" :stat="computedData.days[6]" />
    </div>
  </div>
</template>

<script>
import DayStat from '@/views/Planning/DayStat.vue'

export default {
  name: 'PlanningRow',
  components: { DayStat },
  props: {
    data: {
      required: true
    }
  },
  computed: {
    computedData: function() {
      if (this.data) {
        return this.data
      }
      return { loading: true, days: [] }
    }
  }
}
</script>

<style lang="scss" scoped>
.day-stat-row {
  display: inline-flex;
  .day {
    display: block;
    text-align: center;
    width: 661px;
    height: 71px;
    padding: 10px 0;
    margin-right: 5px;
    font-size: 10px;
    font-weight: bold;
    color: #656565;
    box-sizing: border-box;
  }
}
</style>
