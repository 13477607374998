<template>
  <LoadingComponent v-if="loading" />
  <div v-else class="day-summary">
    <table class="grid">
      <colgroup>
        <col width="22px" />
        <col width="68px" />
        <col width="60px" />
        <col width="33px" />
        <col width="33px" />
        <col width="33px" />
        <col width="33px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="33px" />
        <col width="33px" />
        <col width="25px" />
        <col width="25px" />
        <col width="25px" />
        <col width="70px" />
      </colgroup>
      <tr>
        <th>H</th>
        <th>Lead</th>
        <th>Intervenants</th>
        <th title="Code postal">CP</th>
        <th>Trajet</th>
        <th>Pose</th>
        <th class="grey-header" title="Total d'heures">T(h)</th>
        <th class="blue-header" title="Soufflé">S</th>
        <th class="blue-header" title="Déroulé">D</th>
        <th class="blue-header" title="Rampant">R</th>
        <th class="blue-header" title="Mur">M</th>
        <th class="blue-header" title="Plafond">Pl</th>
        <th class="blue-header" title="Pignon">Pi</th>
        <th class="blue-header" title="Vide sanitaire">VS</th>
        <th class="blue-header" title="ITI+">ITI+</th>
        <th class="grey-header" title="Total des surfaces">T(m²)</th>
        <th class="icon-wrapper w13" title="Camion">
          <font-awesome-icon class="icon" :icon="['fas', 'truck']" />
        </th>
        <th class="icon-wrapper w14" title="Dossier">
          <font-awesome-icon class="icon" :icon="['fas', 'file-alt']" />
        </th>
        <th class="icon-wrapper w15" title="Photo">
          <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
        </th>
        <th class="icon-wrapper w15 pt-1" title="Confirmé">
          <WorkerIcon :height="'15px'" :width="'15px'" :fill="'#5e5e5e'" />
        </th>
        <th>Stade</th>
      </tr>
      <tr
        v-for="row in computedDay.dayRows"
        :key="row.id"
        :class="{
          off: !row.active,
          disabled: row.lead && row.lead.disabled === 1,
          simulatable: row.simulatable
        }"
      >
        <td class="light-grey-column">
          {{ row.lead ? row.lead.hour_trav_planif : '' }}
          <div
            v-if="row.simulatable && simulated"
            class="action"
            @click="resetSimulation"
          >
            <font-awesome-icon class="icon" icon="minus-square" />
          </div>
        </td>
        <td class="commentable">
          <TooltipComment
            v-if="row.lead"
            placement="right-start"
            :lead="row.lead"
            :canEdit="canEdit"
          >
            <TooltipLink
              :name="row.lead.title_contact"
              :dealId="row.lead.project_id"
              :lead="row.lead"
              @changeSav="refreshPlanning"
            >
              <div class="title-container">
                <ColorTag
                  :color="
                    row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]
                  "
                  :style="{
                    left: '2px',
                    bottom: '2px',
                    top: '2px',
                    width: '10px',
                    'border-radius': '2px'
                  }"
                />
                <span
                  v-if="row.lead.comments && row.lead.comments.count"
                  class="comment-tag"
                ></span>
                <span>{{ row.lead.title_contact }}</span>
              </div>
            </TooltipLink>
          </TooltipComment>
        </td>
        <td
          class="text-left pl-1 pr-1"
          :title="row.lead ? row.lead.title_agent : ''"
        >
          <TooltipEditIntervenants
            v-if="row.lead"
            :canEdit="canEdit && !row.lead.disabled"
            :lead="row.lead"
          >
          </TooltipEditIntervenants>
          {{
            row.lead && row.lead.title_agent
              ? row.lead.title_agent.split(' ')[0]
              : ''
          }}
        </td>
        <td>
          {{ row.lead ? row.lead.zipCode : '' }}
          <input
            v-if="row.simulatable"
            :id="technician.id"
            type="number"
            :value="simulated ? simulation[row.index].zipCode : null"
            @change="handleInputChange($event, 'zipCode', row.index)"
          />
        </td>
        <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].trajet_time_tech
              : row.lead
              ? row.lead.trajet_time_tech
              : null | time
          }}
        </td>
        <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].pose_time_tech
              : row.lead
              ? row.lead.pose_time_tech
              : null | time
          }}
        </td>
        <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].trajet_pose_tech_h_sum
              : row.lead
              ? row.lead.trajet_pose_tech_h_sum
              : null | time
          }}
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_souffle > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_souffle"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_souffle && row.lead.surface_souffle_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_souffle +
                    ', Posé: ' +
                    row.lead.surface_souffle_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_souffle > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_souffle_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_souffle }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_souffle : null"
            @change="handleInputChange($event, 'surface_souffle', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_deroule > 0,
            editable: canEdit
          }"
        >
          {{ row.lead ? row.lead.surface_deroule : '' }}
          <TooltipEdit
            v-if="row.lead"
            field="surface_deroule"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_deroule && row.lead.surface_deroule_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_deroule +
                    ', Posé: ' +
                    row.lead.surface_deroule_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_deroule > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_deroule_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_deroule }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_deroule : null"
            @change="handleInputChange($event, 'surface_deroule', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_rampant > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_rampant"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_rampant && row.lead.surface_rampant_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_rampant +
                    ', Posé: ' +
                    row.lead.surface_rampant_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_rampant > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_rampant_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_rampant }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_rampant : null"
            @change="handleInputChange($event, 'surface_rampant', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_mur > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_mur"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_mur && row.lead.surface_mur_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_mur +
                    ', Posé: ' +
                    row.lead.surface_mur_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_mur > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div class="info-surface" v-if="row.lead.surface_mur_pose > 0">
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_mur }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_mur : null"
            @change="handleInputChange($event, 'surface_mur', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_plafond > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_plafond"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_plafond && row.lead.surface_plafond_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_plafond +
                    ', Posé: ' +
                    row.lead.surface_plafond_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_plafond > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_plafond_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_plafond }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_plafond : null"
            @change="handleInputChange($event, 'surface_plafond', row.index)"
          />
        </td>

        <td
          :class="{
            colorable: row.lead && row.lead.surface_pignon > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_pignon"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_pignon && row.lead.surface_pignon_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_pignon +
                    ', Posé: ' +
                    row.lead.surface_pignon_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_pignon > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div class="info-surface" v-if="row.lead.surface_pignon_pose > 0">
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_pignon }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_pignon : null"
            @change="handleInputChange($event, 'surface_pignon', row.index)"
          />
        </td>

        <td
          :class="{
            colorable: row.lead && row.lead.surface_vide_sanitaire > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_vide_sanitaire"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_vide_sanitaire &&
                row.lead.surface_vide_sanitaire_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_vide_sanitaire +
                    ', Posé: ' +
                    row.lead.surface_vide_sanitaire_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_vide_sanitaire > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_vide_sanitaire_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_vide_sanitaire }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="
              simulated ? simulation[row.index].surface_vide_sanitaire : null
            "
            @change="
              handleInputChange($event, 'surface_vide_sanitaire', row.index)
            "
          />
        </td>

        <td
          :class="{
            colorable: row.lead && row.lead.surface_iti > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_iti"
            :weekId="week_id"
            :lead="row.lead"
            :canEdit="canEdit && !row.lead.disabled"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_iti && row.lead.surface_iti_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_iti +
                    ', Posé: ' +
                    row.lead.surface_iti_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_iti > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div class="info-surface" v-if="row.lead.surface_iti_pose > 0">
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_iti }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_iti : null"
            @change="handleInputChange($event, 'surface_iti', row.index)"
          />
        </td>

        <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].total
              : row.lead
              ? Math.round(
                  (parseFloat(row.lead.total) + Number.EPSILON) * 100
                ) / 100
              : ''
          }}
        </td>
        <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].camion
              : row.lead
              ? row.lead.camion
                ? Math.round(row.lead.camion * 100) + '%'
                : ''
              : ''
          }}
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="dossier[row.id]"
            @change="handleCheckboxChange('dossier', row)"
          />
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="photo[row.id]"
            @change="handleCheckboxChange('photo', row)"
          />
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="confirmation[row.id]"
            @change="handleCheckboxChange('confirmation', row)"
          />
        </td>
        <td class="editable">
          <TooltipPipeLine
            v-if="row.lead"
            :lead="row.lead"
            placement="auto"
            :canEdit="canEdit && !row.lead.disabled"
            @changeStage="refreshPlanning"
          >
            <Stage v-if="row.lead" :stage_id="row.lead.cstage_id" />
          </TooltipPipeLine>
          <b-form-checkbox
            v-else-if="canEdit && !computedDay.optimised"
            switch
            :checked="row.active"
            @change="handleSwitchChange(row)"
          ></b-form-checkbox>
        </td>
      </tr>
      <tr>
        <th rowspan="2" class="off"></th>
        <th>CA</th>
        <th colspan="2">Planifié/Posé</th>
        <th>Trajet</th>
        <th>Pose</th>
        <th>T(h)</th>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_s_planifie
              : computedDay.total_s_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_d_planifie
              : computedDay.total_d_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_r_planifie
              : computedDay.total_r_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_m_planifie
              : computedDay.total_m_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_p_planifie
              : computedDay.total_p_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_pignon_planifie
              : computedDay.total_pignon_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_vs_planifie
              : computedDay.total_vs_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{ simulated ? simulation.iti_day : computedDay.iti_day }}
        </td>
        <td>
          {{
            simulated
              ? simulation.total_total_m_planifie
              : computedDay.total_total_m_planifie
          }}
        </td>
        <th colspan="4">Planifié</th>
        <th
          rowspan="2"
          class="action"
          :class="{ optimised: computedDay.optimised }"
          @click="toggleOptimised"
        >
          {{ computedDay.optimised ? 'Optimisé' : 'Non optimisé' }}
        </th>
      </tr>
      <tr>
        <td>
          {{ computedDay.ca_d }}
        </td>
        <td colspan="2">
          {{
            computedDay.pose_planifie_d
              ? computedDay.pose_planifie_d.toFixed(2) + '%'
              : ''
          }}
        </td>
        <td>
          <span>
            {{
              simulated
                ? simulation.total_trajet
                : computedDay.total_trajet | time
            }}
          </span>
        </td>
        <td>
          <span>
            {{
              simulated ? simulation.total_pose : computedDay.total_pose | time
            }}
          </span>
        </td>
        <td>
          <span>
            {{
              simulated
                ? simulation.total_total_h
                : computedDay.total_total_h | time
            }}
          </span>
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_s_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_d_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_r_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_m_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_p_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_pignon_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_vs_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_iti_pose }}
        </td>
        <td>
          {{ computedDay.total_total_m_pose }}
        </td>
        <th colspan="4">Posé</th>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingComponent from '@/components/ui/LoadingComponent'
import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
export default {
  name: 'DaySummary',
  components: {
    LoadingComponent,
    TooltipPipeLine: () => import('@/components/ui/TooltipPipeLine'),
    TooltipComment: () => import('@/components/ui/TooltipComment'),
    TooltipEdit: () => import('@/components/ui/TooltipEdit'),
    TooltipEditIntervenants: () =>
      import('@/components/ui/TooltipEditIntervenants'),
    TooltipLink: () => import('@/components/ui/TooltipLink'),
    ColorTag: () => import('@/components/ui/ColorTag'),
    Stage: () => import('@/components/Stage'),
    WorkerIcon: () => import('@/components/ui/WorkerIcon.vue')
  },
  props: {
    day: {
      required: true
    },
    technician: {
      required: true
    },
    week_id: {
      required: true
    },
    depot_zipCode: {
      required: false
    },
    user_zipCode: {
      required: false
    },
    canEdit: {
      default: false
    },
    loading: {
      default: false
    }
  },
  data() {
    return {
      dossier: [],
      photo: [],
      confirmation: [],
      simulation: {
        1: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        2: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        3: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        4: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        5: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        total_trajet: 0,
        total_pose: 0,
        total_total_h: 0,
        total_s_planifie: 0,
        total_d_planifie: 0,
        total_r_planifie: 0,
        total_m_planifie: 0,
        total_p_planifie: 0,
        total_pignon_planifie: 0,
        total_vs_planifie: 0,
        iti_day: 0,
        total_total_m_planifie: 0
      },
      simulated: false
    }
  },
  methods: {
    ...mapActions([
      'updateFieldOptimise',
      'changeCEEPhotoMeeting',
      'updateRowStatus',
      'getSimulatedTrajet',
      'getSimulatedTotalSurface',
      'getSimulatedPose',
      'getSimulatedCamion',
      'fetchPlanning'
    ]),
    toggleOptimised() {
      if (
        this.currentUser.isSuper ||
        (this.canEdit &&
          (this.currentUser.type === 'user.admin' ||
            this.currentUser.type === 'user.resp.plan')) ||
        (this.currentUser.id = 687)
      ) {
        this.updateFieldOptimise({
          day: this.computedDay
        })
      }
    },
    handleCheckboxChange(field, row) {
      this.$nextTick(() => {
        this.changeCEEPhotoMeeting({
          lead: row.lead,
          field: field,
          value: row.lead[field] == 1 ? 0 : 1
        })
      })
    },
    handleSwitchChange(row) {
      this.updateRowStatus({
        row: row,
        day: this.computedDay
      })
    },
    async refreshPlanning() {
      await this.fetchPlanning()
    },
    handleInputChange(e, field, index) {
      this.simulation[index][field] = e.target.value
      this.$nextTick(async () => {
        this.simulated = true
        if (field == 'zipCode') {
          let origin = this.depot_zipCode
          if (this.user_zipCode) {
            origin = this.user_zipCode
          }
          if (
            index > 1 &&
            this.computedDay.dayRows[index - 2].lead &&
            this.computedDay.dayRows[index - 2].lead.zipCode
          ) {
            origin = this.computedDay.dayRows[index - 2].lead.zipCode
          }
          this.simulation[index][
            'trajet_time_tech'
          ] = await this.getSimulatedTrajet({
            origin: origin,
            destination: this.simulation[index].zipCode
          })
        }

        this.simulation[index]['total'] = await this.getSimulatedTotalSurface(
          this.simulation[index]
        )

        this.simulation[index]['pose_time_tech'] = await this.getSimulatedPose(
          this.simulation[index]
        )

        const camion = await this.getSimulatedCamion(this.simulation[index])

        this.simulation[index]['camion'] = camion
          ? (Math.round((camion * 100 + Number.EPSILON) * 100) / 100).toFixed(
              0
            ) + '%'
          : ''

        if (this.simulation[index]['trajet_time_tech']) {
          this.simulation[index]['trajet_pose_tech_h_sum'] += this.simulation[
            index
          ]['trajet_time_tech']
          this.simulation['total_trajet'] =
            this.computedDay.total_trajet +
            this.simulation[index]['trajet_time_tech']
        }
        if (this.simulation[index]['pose_time_tech']) {
          this.simulation[index]['trajet_pose_tech_h_sum'] += this.simulation[
            index
          ]['pose_time_tech']
          this.simulation['total_pose'] =
            this.computedDay.total_pose +
            this.simulation[index]['pose_time_tech']
        }

        this.simulation['total_total_h'] =
          this.computedDay.total_total_h +
          this.simulation[index]['trajet_pose_tech_h_sum']

        this.simulation['total_s_planifie'] =
          this.computedDay.total_s_planifie +
          parseInt(
            this.simulation[index].surface_souffle
              ? this.simulation[index].surface_souffle
              : 0
          )
        this.simulation['total_d_planifie'] =
          this.computedDay.total_d_planifie +
          parseInt(
            this.simulation[index].surface_deroule
              ? this.simulation[index].surface_deroule
              : 0
          )
        this.simulation['total_r_planifie'] =
          this.computedDay.total_r_planifie +
          parseInt(
            this.simulation[index].surface_rampant
              ? this.simulation[index].surface_rampant
              : 0
          )
        this.simulation['total_m_planifie'] =
          this.computedDay.total_m_planifie +
          parseInt(
            this.simulation[index].surface_mur
              ? this.simulation[index].surface_mur
              : 0
          )
        this.simulation['total_p_planifie'] =
          this.computedDay.total_p_planifie +
          parseInt(
            this.simulation[index].surface_plafond
              ? this.simulation[index].surface_plafond
              : 0
          )

        this.simulation['total_pignon_planifie'] =
          this.computedDay.total_pignon_planifie +
          parseInt(
            this.simulation[index].surface_pignon
              ? this.simulation[index].surface_pignon
              : 0
          )
        this.simulation['total_vs_planifie'] =
          this.computedDay.total_vs_planifie +
          parseInt(
            this.simulation[index].surface_vide_sanitaire
              ? this.simulation[index].surface_vide_sanitaire
              : 0
          )
        this.simulation['iti_day'] =
          this.computedDay.iti_day +
          parseInt(
            this.simulation[index].surface_iti
              ? this.simulation[index].surface_iti
              : 0
          )
        this.simulation['total_total_m_planifie'] =
          this.computedDay.total_total_m_planifie +
          parseInt(
            this.simulation[index]['total']
              ? this.simulation[index]['total']
              : 0
          )
      })
    },
    resetSimulation() {
      this.simulation = {
        1: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        2: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        3: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        4: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        5: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        total_trajet: 0,
        total_pose: 0,
        total_total_h: 0,
        total_s_planifie: 0,
        total_d_planifie: 0,
        total_r_planifie: 0,
        total_m_planifie: 0,
        total_p_planifie: 0,
        total_pignon_planifie: 0,
        total_vs_planifie: 0,
        iti_day: 0,
        total_total_m_planifie: 0
      }
      this.simulated = false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'getPlanningPipeline']),
    colorStadeTech: function() {
      let status = []
      for (let index = 0; index < this.computedDay.dayRows.length; index++) {
        const element = this.computedDay.dayRows[index]
        if (
          element.lead &&
          element.lead.cpipeline_id &&
          element.lead.cstage_id &&
          element.lead.csubstage_id
        ) {
          this.getPlanningPipeline.forEach(pipeline => {
            if (pipeline.id == element.lead.cpipeline_id) {
              pipeline.stages.forEach(stages => {
                if (stages.id == element.lead.cstage_id) {
                  stages.csubstages.data.filter(substage => {
                    if (substage.id == element.lead.csubstage_id) {
                      status[element.index] = substage.color
                    }
                  })
                }
              })
            }
          })
        }
      }
      return status
    },
    computedDay: function() {
      if (this.day) {
        this.day.dayRows.forEach(row => {
          if (row.lead) {
            this.dossier[row.id] = row.lead.dossier
            this.photo[row.id] = row.lead.photo
            this.confirmation[row.id] = row.lead.confirmation
          }
        })
        return this.day
      }
      return {}
    }
  },
  filters: {
    time: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 10) return '00h0' + value
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    },
    colorStatus: function(value) {
      switch (value) {
        case 'red':
          return 'redStatus'
        case 'orange':
          return 'orangeStatus'
        case 'yellow':
          return 'yellow'
        case 'green':
          return 'green'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.day-summary {
  table {
    &.grid {
      width: 661px;
      border-collapse: collapse;
      table-layout: fixed;
      box-sizing: border-box;
      empty-cells: show;
      outline: 0;

      tr {
        height: 25px;

        th,
        td {
          position: relative;
          text-align: center;
          border: 1px solid #e8e8e8;
          outline: 0;
          vertical-align: middle;
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
          font-size: 9px;
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 1px;

          &.off {
            background-color: #9c9c9c;
            border-color: #8e8e8e;
          }
        }

        th {
          font-weight: 700;
          background-color: #ededed;
          &.blue-header {
            background-color: #2dabe2;
            color: #fff;
          }
          &.grey-header {
            background-color: #6c757d;
            color: #fff;
          }

          &.icon-wrapper {
            padding: 0 2px;
            &.w13 {
              font-size: 13px;
            }
            &.w14 {
              font-size: 14px;
            }
            &.w15 {
              font-size: 15px;
            }
          }

          &.action {
            position: relative;
            padding: 0 2px;
            cursor: pointer;
            &.optimised {
              background-color: #2dabe2;
              color: #fff;
            }
          }
        }
        td {
          &.light-grey-column {
            background-color: #dbdbdb;
          }
          .planning-checkbox {
            padding-left: 1.6rem;
          }
        }

        &.off {
          th,
          td {
            background-color: #9c9c9c;
            border-color: #9c9c9c;
          }
        }
        &.disabled {
          th,
          td {
            background-color: #dbdbdb;
            border-color: #dbdbdb;
          }
        }

        &.simulatable {
          td {
            position: relative;
            input {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: none;
              text-align: center;
            }

            .action {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #e73e01;
            }
          }
        }

        &:hover {
          color: #656565;
          background-color: #ededed;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.day-summary {
  table {
    &.grid {
      td {
        .v-popover {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .trigger {
            display: block !important;
            position: relative;
            width: 100%;
            height: 100%;
          }
        }

        &.editable {
          position: relative;
          .edit {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            font-size: 12px;
            justify-content: center;
            align-items: center;
            background: #dbdbdb;
            color: #2dabe2;
            z-index: 3;
            cursor: pointer;
          }

          .content {
            height: 100%;
            padding: 2px 2px 0;
            .info-surface {
              position: absolute;
              top: -7px;
              right: 1px;
              font-size: 7px;
              color: #2dabe2;
              z-index: 1;
            }
          }
          &:hover {
            .edit {
              display: flex;
            }
          }
        }

        &.commentable {
          position: relative;
          .v-popover {
            .trigger {
              .title-container {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                padding-left: 15px;
                .comment-tag {
                  position: absolute;
                  width: 20px;
                  height: 10px;
                  background: #ffe436;
                  z-index: 9;
                  display: block;
                  top: -2px;
                  right: -7px;
                  transform: rotateZ(45deg);
                }
                span {
                  outline: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        &.colorable {
          .value {
            position: relative;
            z-index: 2;
          }
        }
      }
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2dabe2;
    background-color: #2dabe2;
  }
}
</style>
